define("ember-svg-jar/inlined/duplicate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15 3C10.04 3 6 7.04 6 12C6 16.96 10.04 21 15 21C19.96 21 24 16.96 24 12C24 7.04 19.96 3 15 3ZM6.01 5.68C3.64 6.8 2 9.21 2 12C2 14.79 3.64 17.2 6.01 18.32V20.48C2.52 19.24 0 15.91 0 12C0 8.09 2.52 4.76 6.01 3.52V5.68ZM16 8V11H19V13H16V16H14V13H11V11H14V8H16ZM8 12C8 15.86 11.14 19 15 19C18.86 19 22 15.86 22 12C22 8.14 18.86 5 15 5C11.14 5 8 8.14 8 12Z\" fill=\"#7C849B\"/>\n",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});