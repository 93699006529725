define("ember-svg-jar/inlined/add-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M16.3337 10.8333H11.3337V15.8333H9.66699V10.8333H4.66699V9.16663H9.66699V4.16663H11.3337V9.16663H16.3337V10.8333Z\" fill=\"#0B80F3\"/>\n",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 21 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});