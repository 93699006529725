define("ember-svg-jar/inlined/icon-pdf-grey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.334 0.333496H4.33398C3.60065 0.333496 3.00065 0.933496 3.00065 1.66683V9.66683C3.00065 10.4002 3.60065 11.0002 4.33398 11.0002H12.334C13.0673 11.0002 13.6673 10.4002 13.6673 9.66683V1.66683C13.6673 0.933496 13.0673 0.333496 12.334 0.333496ZM6.66732 5.3335C6.66732 5.88683 6.22065 6.3335 5.66732 6.3335H5.00065V7.66683H4.00065V3.66683H5.66732C6.22065 3.66683 6.66732 4.1135 6.66732 4.66683V5.3335ZM9.00065 7.66683C9.55398 7.66683 10.0007 7.22016 10.0007 6.66683V4.66683C10.0007 4.1135 9.55398 3.66683 9.00065 3.66683H7.33398V7.66683H9.00065ZM12.6673 4.66683H11.6673V5.3335H12.6673V6.3335H11.6673V7.66683H10.6673V3.66683H12.6673V4.66683ZM5.66732 5.3335H5.00065V4.66683H5.66732V5.3335ZM1.66732 3.00016H0.333984V12.3335C0.333984 13.0668 0.933984 13.6668 1.66732 13.6668H11.0007V12.3335H1.66732V3.00016ZM9.00065 6.66683H8.33399V4.66683H9.00065V6.66683Z\" fill=\"#7C849B\"/>\n",
    "attrs": {
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});