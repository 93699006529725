define("ember-svg-jar/inlined/warning-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M2.695 16.1a.68.68 0 0 1-.582-1.028l6.906-11.64a.675.675 0 0 1 1.163 0l6.905 11.64a.68.68 0 0 1-.58 1.028zm7.685-8.667H8.864l.163 4.355h1.205zm-.763 5.465a.747.747 0 0 0-.753.752.75.75 0 0 0 .753.764.752.752 0 0 0 .763-.764.75.75 0 0 0-.763-.752z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 19.2 19.2"
    }
  };
  _exports.default = _default;
});