define("ember-svg-jar/inlined/file-type-document-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M8 0C5.79086 0 4 1.79086 4 4V36C4 38.2091 5.79086 40 8 40H32C34.2091 40 36 38.2091 36 36V12C36 11.6799 35.8728 11.3728 35.6464 11.1464L24.8536 0.353553C24.6272 0.127177 24.3201 0 24 0H8Z\" fill=\"white\"/>\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M32 38.5H8C6.61929 38.5 5.5 37.3807 5.5 36V4C5.5 2.61929 6.61929 1.5 8 1.5H23.25V8C23.25 10.6234 25.3766 12.75 28 12.75H34.5V36C34.5 37.3807 33.3807 38.5 32 38.5ZM33.6287 11.25L24.75 2.37132V8C24.75 9.79493 26.2051 11.25 28 11.25H33.6287ZM4 4C4 1.79086 5.79086 0 8 0H24C24.3201 0 24.6272 0.127177 24.8536 0.353553L35.6464 11.1464C35.8728 11.3728 36 11.6799 36 12V36C36 38.2091 34.2091 40 32 40H8C5.79086 40 4 38.2091 4 36V4Z\" fill=\"#CDD5DF\"/>\n<path d=\"M25.8333 16.5H14.1667C13.25 16.5 12.5 17.25 12.5 18.1667V29.8333C12.5 30.75 13.25 31.5 14.1667 31.5H25.8333C26.75 31.5 27.5 30.75 27.5 29.8333V18.1667C27.5 17.25 26.75 16.5 25.8333 16.5ZM20.8333 28.1667H16.6667C16.2083 28.1667 15.8333 27.7917 15.8333 27.3333C15.8333 26.875 16.2083 26.5 16.6667 26.5H20.8333C21.2917 26.5 21.6667 26.875 21.6667 27.3333C21.6667 27.7917 21.2917 28.1667 20.8333 28.1667ZM23.3333 24.8333H16.6667C16.2083 24.8333 15.8333 24.4583 15.8333 24C15.8333 23.5417 16.2083 23.1667 16.6667 23.1667H23.3333C23.7917 23.1667 24.1667 23.5417 24.1667 24C24.1667 24.4583 23.7917 24.8333 23.3333 24.8333ZM23.3333 21.5H16.6667C16.2083 21.5 15.8333 21.125 15.8333 20.6667C15.8333 20.2083 16.2083 19.8333 16.6667 19.8333H23.3333C23.7917 19.8333 24.1667 20.2083 24.1667 20.6667C24.1667 21.125 23.7917 21.5 23.3333 21.5Z\" fill=\"#1570EF\"/>\n",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});