define("ember-svg-jar/inlined/info-simple-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.79297 2.5C6.79297 3.05228 6.34525 3.5 5.79297 3.5C5.24068 3.5 4.79297 3.05228 4.79297 2.5C4.79297 1.94772 5.24068 1.5 5.79297 1.5C6.34525 1.5 6.79297 1.94772 6.79297 2.5ZM4.125 5.56177V4.62427H6C6.25888 4.62427 6.46875 4.83413 6.46875 5.09302V9.30812H7.875V10.2456H4.125V9.30812H5.53125V5.56177H4.125Z\" fill=\"#364152\"/>\n",
    "attrs": {
      "viewBox": "0 0 12 12",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});