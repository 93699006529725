define("ember-svg-jar/inlined/job-notes-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M7.78943 7.78955H16.2105V9.89484H7.78943V7.78955Z\" fill=\"#7C849B\"/>\n<path d=\"M7.78943 13.0527V10.9474H16.2105V13.0527H7.78943Z\" fill=\"#7C849B\"/>\n<path d=\"M16.2105 14.1053H7.78943V16.2106H16.2105V14.1053Z\" fill=\"#7C849B\"/>\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.57996 5.58008C3.57996 4.47552 4.47537 3.58008 5.57996 3.58008H18.42C19.5245 3.58008 20.42 4.47552 20.42 5.58008V18.4201C20.42 19.5247 19.5245 20.4201 18.42 20.4201H5.57996C4.47537 20.4201 3.57996 19.5247 3.57996 18.4201V5.58008ZM5.69995 5.70007H18.3V18.3H5.69995V5.70007Z\" fill=\"#7C849B\"/>\n",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});