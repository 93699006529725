define("ember-svg-jar/inlined/playlist-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.33325 5.99967H9.33325V7.33301H1.33325V5.99967ZM1.33325 3.33301H9.33325V4.66634H1.33325V3.33301ZM6.66659 9.99967H1.33325V8.66634H6.66659V9.99967ZM13.8855 14.1614L14.8283 13.2186L12.9427 11.333L14.8283 9.44738L13.8855 8.50458L11.9999 10.3902L10.1143 8.50458L9.17149 9.44738L11.0571 11.333L9.17149 13.2186L10.1143 14.1614L11.9999 12.2758L13.8855 14.1614Z\"/>\n",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});