define("ember-svg-jar/inlined/job-notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M7.78955 7.78955H16.2106V9.89484H7.78955V7.78955Z\" fill=\"#7C849B\"/>\n<path d=\"M7.78955 13.0527V10.9474H16.2106V13.0527H7.78955Z\" fill=\"#7C849B\"/>\n<path d=\"M16.2106 14.1053H7.78955V16.2106H16.2106V14.1053Z\" fill=\"#7C849B\"/>\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.58008 5.58008C3.58008 4.47552 4.47549 3.58008 5.58008 3.58008H18.4201C19.5247 3.58008 20.4201 4.47552 20.4201 5.58008V18.4201C20.4201 19.5247 19.5247 20.4201 18.4201 20.4201H5.58008C4.47549 20.4201 3.58008 19.5247 3.58008 18.4201V5.58008ZM5.70007 5.70007H18.3001V18.3H5.70007V5.70007Z\" fill=\"#7C849B\"/>\n",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});