define("ember-svg-jar/inlined/appointment-progress-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.7139 6.3999C10.7139 8.60904 8.92301 10.3999 6.71387 10.3999C4.50473 10.3999 2.71387 8.60904 2.71387 6.3999C2.71387 4.19076 4.50473 2.3999 6.71387 2.3999C8.92301 2.3999 10.7139 4.19076 10.7139 6.3999ZM11.7139 6.3999C11.7139 9.16133 9.47529 11.3999 6.71387 11.3999C3.95244 11.3999 1.71387 9.16133 1.71387 6.3999C1.71387 3.63848 3.95244 1.3999 6.71387 1.3999C9.47529 1.3999 11.7139 3.63848 11.7139 6.3999ZM3.71387 6.3999C3.71387 5.9874 4.05137 5.6499 4.46387 5.6499C4.87637 5.6499 5.21387 5.9874 5.21387 6.3999C5.21387 6.8124 4.87637 7.1499 4.46387 7.1499C4.05137 7.1499 3.71387 6.8124 3.71387 6.3999ZM8.96387 5.6499C8.55137 5.6499 8.21387 5.9874 8.21387 6.3999C8.21387 6.8124 8.55137 7.1499 8.96387 7.1499C9.37637 7.1499 9.71387 6.8124 9.71387 6.3999C9.71387 5.9874 9.37637 5.6499 8.96387 5.6499ZM5.96387 6.3999C5.96387 5.9874 6.30137 5.6499 6.71387 5.6499C7.12637 5.6499 7.46387 5.9874 7.46387 6.3999C7.46387 6.8124 7.12637 7.1499 6.71387 7.1499C6.30137 7.1499 5.96387 6.8124 5.96387 6.3999Z\" fill=\"white\"/>\n",
    "attrs": {
      "width": "13",
      "height": "13",
      "viewBox": "0 0 13 13",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});