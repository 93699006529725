define("ember-svg-jar/inlined/drag-indicator-vertical-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5.99999 2.66669C5.26666 2.66669 4.66666 3.26669 4.66666 4.00002C4.66666 4.73335 5.26666 5.33335 5.99999 5.33335C6.73332 5.33335 7.33332 4.73335 7.33332 4.00002C7.33332 3.26669 6.73332 2.66669 5.99999 2.66669ZM4.66666 8.00002C4.66666 7.26669 5.26666 6.66669 5.99999 6.66669C6.73332 6.66669 7.33332 7.26669 7.33332 8.00002C7.33332 8.73335 6.73332 9.33335 5.99999 9.33335C5.26666 9.33335 4.66666 8.73335 4.66666 8.00002ZM5.99999 13.3334C6.73332 13.3334 7.33332 12.7334 7.33332 12C7.33332 11.2667 6.73332 10.6667 5.99999 10.6667C5.26666 10.6667 4.66666 11.2667 4.66666 12C4.66666 12.7334 5.26666 13.3334 5.99999 13.3334ZM11.3333 4.00002C11.3333 4.73335 10.7333 5.33335 9.99999 5.33335C9.26666 5.33335 8.66666 4.73335 8.66666 4.00002C8.66666 3.26669 9.26666 2.66669 9.99999 2.66669C10.7333 2.66669 11.3333 3.26669 11.3333 4.00002ZM9.99999 6.66669C9.26666 6.66669 8.66666 7.26669 8.66666 8.00002C8.66666 8.73335 9.26666 9.33335 9.99999 9.33335C10.7333 9.33335 11.3333 8.73335 11.3333 8.00002C11.3333 7.26669 10.7333 6.66669 9.99999 6.66669ZM8.66666 12C8.66666 11.2667 9.26666 10.6667 9.99999 10.6667C10.7333 10.6667 11.3333 11.2667 11.3333 12C11.3333 12.7334 10.7333 13.3334 9.99999 13.3334C9.26666 13.3334 8.66666 12.7334 8.66666 12Z\" fill=\"#7C849B\"/>\n",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});