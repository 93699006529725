define("ember-svg-jar/inlined/add-new-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M16.6666 11.111H11.1111V16.6666H8.88887V11.111H3.33331V8.88881H8.88887V3.33325H11.1111V8.88881H16.6666V11.111Z\" fill=\"white\"/>\n",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});