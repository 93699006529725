define("ember-svg-jar/inlined/share-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M13.5 12.09C12.93 12.09 12.42 12.315 12.03 12.6675L6.6825 9.555C6.72 9.3825 6.75 9.21 6.75 9.03C6.75 8.85 6.72 8.6775 6.6825 8.505L11.97 5.4225C12.375 5.7975 12.9075 6.03 13.5 6.03C14.745 6.03 15.75 5.025 15.75 3.78C15.75 2.535 14.745 1.53 13.5 1.53C12.255 1.53 11.25 2.535 11.25 3.78C11.25 3.96 11.28 4.1325 11.3175 4.305L6.03 7.3875C5.625 7.0125 5.0925 6.78 4.5 6.78C3.255 6.78 2.25 7.785 2.25 9.03C2.25 10.275 3.255 11.28 4.5 11.28C5.0925 11.28 5.625 11.0475 6.03 10.6725L11.37 13.7925C11.3325 13.95 11.31 14.115 11.31 14.28C11.31 15.4875 12.2925 16.47 13.5 16.47C14.7075 16.47 15.69 15.4875 15.69 14.28C15.69 13.0725 14.7075 12.09 13.5 12.09Z\" fill=\"#1570EF\"/>\n",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});