define("ember-svg-jar/inlined/trash-with-x-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.28637 16.4813C4.28637 17.4998 5.14351 18.3332 6.19113 18.3332H13.8102C14.8578 18.3332 15.7149 17.4998 15.7149 16.4813V5.37021H4.28637V16.4813ZM7.97208 8.58317L6.62922 9.88873L8.65779 11.8517L6.63875 13.8147L7.9816 15.1202L10.0007 13.1572L12.0197 15.1202L13.3626 13.8147L11.3435 11.8517L13.3626 9.88873L12.0197 8.58317L10.0007 10.5461L7.97208 8.58317Z\"/>\n<path d=\"M13.334 2.59243L12.3816 1.6665H7.6197L6.66732 2.59243H3.33398V4.44428H16.6673V2.59243H13.334Z\"/>\n",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});