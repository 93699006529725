define("ember-svg-jar/inlined/checkbox-square-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.19444 1.25H16.8056C17.875 1.25 18.75 2.125 18.75 3.19444V16.8056C18.75 17.875 17.875 18.75 16.8056 18.75H3.19444C2.125 18.75 1.25 17.875 1.25 16.8056V3.19444C1.25 2.125 2.125 1.25 3.19444 1.25ZM17.5 3.125C17.5 2.77982 17.2202 2.5 16.875 2.5H3.125C2.77982 2.5 2.5 2.77982 2.5 3.125V16.875C2.5 17.2202 2.77982 17.5 3.125 17.5H16.875C17.2202 17.5 17.5 17.2202 17.5 16.875V3.125Z\" fill=\"#7C849B\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});