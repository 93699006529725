define("ember-svg-jar/inlined/notifications-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.0003 7.33268V10.666L13.3337 11.9993V12.666H2.66699V11.9993L4.00033 10.666V7.33268C4.00033 5.27935 5.08699 3.57268 7.00033 3.11935V2.66602C7.00033 2.11268 7.44699 1.66602 8.00033 1.66602C8.55366 1.66602 9.00033 2.11268 9.00033 2.66602V3.11935C10.907 3.57268 12.0003 5.28602 12.0003 7.33268ZM9.33366 13.3327C9.33366 14.066 8.73366 14.666 8.00033 14.666C7.26033 14.666 6.66699 14.066 6.66699 13.3327H9.33366Z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});