define("ember-svg-jar/inlined/unassign-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M7.01995 3.8327C7.55245 3.0077 8.48245 2.4677 9.53245 2.4677C11.19 2.4677 12.5325 3.8102 12.5325 5.4677C12.5325 6.5177 11.9925 7.4477 11.1675 7.9802L7.01995 3.8327ZM15.5325 12.3452C15.5175 11.5202 15.06 10.7627 14.325 10.3802C13.92 10.1702 13.4775 9.97519 12.9975 9.81019L15.5325 12.3452ZM16.425 15.3602L2.63995 1.5752L1.57495 2.6327L8.24245 9.30019C6.88495 9.47269 5.69995 9.8927 4.73995 10.3877C3.98995 10.7702 3.53245 11.5427 3.53245 12.3827V14.4677H13.41L15.3675 16.4252L16.425 15.3602Z\" fill=\"#7C849B\"/>\n",
    "attrs": {
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});