define("ember-svg-jar/inlined/revert-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M9.02995 10L10.62 11.59L9.19995 13L5.19995 9L9.19995 5L10.61 6.41L9.02995 8H17.2C18.3 8 19.2 8.9 19.2 10V19H17.2V10H9.02995Z\" fill=\"#4B5565\"/>\n",
    "attrs": {
      "viewBox": "0 0 25 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});