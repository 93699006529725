define("ember-svg-jar/inlined/building-icon-alt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.0001 5.83333H18.3334V17.5H1.66675V2.5H10.0001V5.83333ZM3.33341 15.8333H5.00008V14.1667H3.33341V15.8333ZM5.00008 12.5H3.33341V10.8333H5.00008V12.5ZM3.33341 9.16667H5.00008V7.5H3.33341V9.16667ZM5.00008 5.83333H3.33341V4.16667H5.00008V5.83333ZM6.66675 15.8333H8.33341V14.1667H6.66675V15.8333ZM8.33341 12.5H6.66675V10.8333H8.33341V12.5ZM6.66675 9.16667H8.33341V7.5H6.66675V9.16667ZM8.33341 5.83333H6.66675V4.16667H8.33341V5.83333ZM10.0001 15.8333H16.6667V7.5H10.0001V9.16667H11.6667V10.8333H10.0001V12.5H11.6667V14.1667H10.0001V15.8333ZM15.0001 9.16667H13.3334V10.8333H15.0001V9.16667ZM13.3334 12.5H15.0001V14.1667H13.3334V12.5Z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});