define("ember-svg-jar/inlined/drag-indicator-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2.66667 10C2.66667 10.7333 3.26667 11.3333 4 11.3333C4.73333 11.3333 5.33333 10.7333 5.33333 10C5.33333 9.26667 4.73333 8.66667 4 8.66667C3.26667 8.66667 2.66667 9.26667 2.66667 10ZM8 11.3333C7.26667 11.3333 6.66667 10.7333 6.66667 10C6.66667 9.26667 7.26667 8.66667 8 8.66667C8.73333 8.66667 9.33333 9.26667 9.33333 10C9.33333 10.7333 8.73333 11.3333 8 11.3333ZM13.3333 10C13.3333 9.26667 12.7333 8.66667 12 8.66667C11.2667 8.66667 10.6667 9.26667 10.6667 10C10.6667 10.7333 11.2667 11.3333 12 11.3333C12.7333 11.3333 13.3333 10.7333 13.3333 10ZM4 4.66667C4.73333 4.66667 5.33333 5.26667 5.33333 6C5.33333 6.73333 4.73333 7.33333 4 7.33333C3.26667 7.33333 2.66667 6.73333 2.66667 6C2.66667 5.26667 3.26667 4.66667 4 4.66667ZM6.66667 6C6.66667 6.73333 7.26667 7.33333 8 7.33333C8.73333 7.33333 9.33333 6.73333 9.33333 6C9.33333 5.26667 8.73333 4.66667 8 4.66667C7.26667 4.66667 6.66667 5.26667 6.66667 6ZM12 7.33333C11.2667 7.33333 10.6667 6.73333 10.6667 6C10.6667 5.26667 11.2667 4.66667 12 4.66667C12.7333 4.66667 13.3333 5.26667 13.3333 6C13.3333 6.73333 12.7333 7.33333 12 7.33333Z\" fill=\"#7C849B\"/>\n",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});