define("ember-svg-jar/inlined/running-man-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M10.9124 3.9375C11.7374 3.9375 12.4124 3.2625 12.4124 2.4375C12.4124 1.6125 11.7374 0.9375 10.9124 0.9375C10.0874 0.9375 9.4124 1.6125 9.4124 2.4375C9.4124 3.2625 10.0874 3.9375 10.9124 3.9375ZM8.2124 14.3625L8.9624 11.0625L10.5374 12.5625V17.0625H12.0374V11.4375L10.4624 9.9375L10.9124 7.6875C11.8874 8.8125 13.3874 9.5625 15.0374 9.5625V8.0625C13.6124 8.0625 12.4124 7.3125 11.8124 6.2625L11.0624 5.0625C10.7624 4.6125 10.3124 4.3125 9.7874 4.3125C9.5624 4.3125 9.4124 4.3875 9.1874 4.3875L5.2874 6.0375V9.5625H6.7874V7.0125L8.1374 6.4875L6.9374 12.5625L3.2624 11.8125L2.9624 13.3125L8.2124 14.3625Z\" fill=\"#1570EF\"/>\n",
    "attrs": {
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});