define("ember-svg-jar/inlined/unstar-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 17.27L18.18 21L18.078 20.618L18.59 21.13L19.86 19.86L5.27 5.27L4 6.54L6.33244 8.87244L2 9.24L7.46 13.97L5.82 21L12 17.27ZM9.7139 7.3939L16.3887 14.0687L22 9.24L14.81 8.63L12 2L9.7139 7.3939Z\" fill=\"currentColor\"/>\n",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});