define("ember-svg-jar/inlined/arrows-alt-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M1.85 3.615L.586 4.877V3.823H0v1.69c0 .203.164.367.367.367h1.69v-.588H1.003L2.265 4.03zM3.82 0v.588h1.055L3.613 1.851l.416.416 1.263-1.262v1.054h.587V.368A.368.368 0 0 0 5.512 0zm1.47 4.877L4.03 3.615l-.416.416 1.263 1.26H3.82v.59h1.691a.367.367 0 0 0 .367-.369v-1.69h-.587zM1.004.588l1.262 1.263-.416.416L.587 1.005v1.054H0V.368C0 .165.164 0 .367 0h1.69v.588zm0 0\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 5.879 5.88"
    }
  };
  _exports.default = _default;
});