define("ember-svg-jar/inlined/expand-collapse-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<g>\n<rect width=\"32\" height=\"32\" rx=\"16\" fill=\"#1F2021\" fill-opacity=\"0.7\"/>\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.3333 12.6667V11.3333H20.6667V14.6667H19.3333V12.6667H17.3333ZM12.6667 14.6667H11.3334V11.3333H14.6667V12.6667H12.6667V14.6667ZM11.3333 17.3333H12.6667V19.3333H14.6667V20.6667H11.3333V17.3333ZM19.3333 19.3333H17.3333V20.6667H20.6667V17.3333H19.3333V19.3333Z\" fill=\"white\"/>\n</g>\n<defs>\n<filter id=\"filter0_b_146_2584\" x=\"-20\" y=\"-20\" width=\"72\" height=\"72\" filterUnits=\"userSpaceOnUse\" color-interpolation-filters=\"sRGB\">\n<feFlood flood-opacity=\"0\" result=\"BackgroundImageFix\"/>\n<feGaussianBlur in=\"BackgroundImage\" stdDeviation=\"10\"/>\n<feComposite in2=\"SourceAlpha\" operator=\"in\" result=\"effect1_backgroundBlur_146_2584\"/>\n<feBlend mode=\"normal\" in=\"SourceGraphic\" in2=\"effect1_backgroundBlur_146_2584\" result=\"shape\"/>\n</filter>\n</defs>\n",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});