define("ember-svg-jar/inlined/pause-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M7.25 14.25V3.75H5.25V14.25H7.25Z\" fill=\"#DC6803\"/>\n<path d=\"M12.7476 14.25V3.75H10.7476V14.25H12.7476Z\" fill=\"#DC6803\"/>\n",
    "attrs": {
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});