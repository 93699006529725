define("@adopted-ember-addons/ember-stripe-elements/components/stripe-elements", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
    card=(component "stripe-card"
      _elements=this.elements
    )
    cardNumber=(component "stripe-card-number"
      _elements=this.elements
    )
    cardExpiry=(component "stripe-card-expiry"
      _elements=this.elements
    )
    cardCvc=(component "stripe-card-cvc"
      _elements=this.elements
    )
    postalCode=(component "stripe-postal-code"
      _elements=this.elements
    )
  )}}
  
  */
  {
    "id": "VT/LlkAo",
    "block": "[[[18,1,[[28,[37,1],null,[[\"card\",\"cardNumber\",\"cardExpiry\",\"cardCvc\",\"postalCode\"],[[50,\"stripe-card\",0,null,[[\"_elements\"],[[30,0,[\"elements\"]]]]],[50,\"stripe-card-number\",0,null,[[\"_elements\"],[[30,0,[\"elements\"]]]]],[50,\"stripe-card-expiry\",0,null,[[\"_elements\"],[[30,0,[\"elements\"]]]]],[50,\"stripe-card-cvc\",0,null,[[\"_elements\"],[[30,0,[\"elements\"]]]]],[50,\"stripe-postal-code\",0,null,[[\"_elements\"],[[30,0,[\"elements\"]]]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@adopted-ember-addons/ember-stripe-elements/components/stripe-elements.hbs",
    "isStrictMode": false
  });
  let StripeElements = (_dec = (0, _service.inject)('stripev3'), (_class = class StripeElements extends _component2.default {
    get options() {
      return this.args.options || {};
    }
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "stripe", _descriptor, this);
      this.elements = this.stripe.elements(this.options);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "stripe", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = StripeElements;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, StripeElements);
});