define("ember-svg-jar/inlined/list-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M2.66732 8.66667C1.92732 8.66667 1.33398 9.26 1.33398 10V12.6667C1.33398 13.4067 1.92732 14 2.66732 14H5.33398C6.07398 14 6.66732 13.4067 6.66732 12.6667V10C6.66732 9.26 6.07398 8.66667 5.33398 8.66667H2.66732ZM5.46732 9.66667L6.17398 10.3667L3.51398 13L1.82732 11.3L2.54065 10.6L3.52065 11.5933L5.46732 9.66667ZM2.66732 2C1.92732 2 1.33398 2.59333 1.33398 3.33333V6C1.33398 6.74 1.92732 7.33333 2.66732 7.33333H5.33398C6.07398 7.33333 6.66732 6.74 6.66732 6V3.33333C6.66732 2.59333 6.07398 2 5.33398 2H2.66732ZM2.66732 3.33333H5.33398V6H2.66732V3.33333ZM8.00065 3.33333H14.6673V4.66667H8.00065V3.33333ZM8.00065 12.6667V11.3333H14.6673V12.6667H8.00065ZM8.00065 7.33333H14.6673V8.66667H8.00065V7.33333Z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});