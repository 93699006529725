define("ember-svg-jar/inlined/time-due-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.33301 7.99967C1.33301 4.31967 4.31301 1.33301 7.99301 1.33301C10.4635 1.33301 12.6196 2.67414 13.7733 4.66634H12.164C11.1868 3.44689 9.68481 2.66634 7.99967 2.66634C5.05301 2.66634 2.66634 5.05301 2.66634 7.99967C2.66634 10.9463 5.05301 13.333 7.99967 13.333C8.70703 13.333 9.38212 13.1955 9.99967 12.9457V14.3596C9.36637 14.5589 8.69228 14.6663 7.99301 14.6663C4.31301 14.6663 1.33301 11.6797 1.33301 7.99967ZM9.99967 9.15523V10.2663L7.33301 8.66634V4.66634H8.33301V8.16634L9.99967 9.15523Z\" fill=\"#E54234\"/>\n<path d=\"M13.3333 14.6663H12V13.333H13.3333V14.6663Z\" fill=\"#E54234\"/>\n<path d=\"M13.3333 12.0003H12V6.66699H13.3333V12.0003Z\" fill=\"#E54234\"/>\n",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});