define("ember-svg-jar/inlined/copy-outline-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.3553 1.66663H4.40798C3.5878 1.66663 2.91675 2.34844 2.91675 3.18178V13.7878H4.40798V3.18178H13.3553V1.66663Z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15.5922 4.69693H7.39043C6.57026 4.69693 5.8992 5.37875 5.8992 6.21208V16.8181C5.8992 17.6515 6.57026 18.3333 7.39043 18.3333H15.5922C16.4124 18.3333 17.0834 17.6515 17.0834 16.8181V6.21208C17.0834 5.37875 16.4124 4.69693 15.5922 4.69693ZM7.39043 16.8181H15.5922V6.21208H7.39043V16.8181Z\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});