define("ember-svg-jar/inlined/filter-triangle-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2 4V5.33333H14V4H2ZM6.66667 12H9.33333V10.6667H6.66667V12ZM12 8.66667H4V7.33333H12V8.66667Z\" fill=\"#0B80F3\"/>\n",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});