define("ember-svg-jar/inlined/list-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M2.667 8.667c-.74 0-1.334.593-1.334 1.333v2.667c0 .74.594 1.333 1.334 1.333h2.666c.74 0 1.334-.593 1.334-1.333V10c0-.74-.594-1.333-1.334-1.333H2.667zm2.8 1l.706.7L3.513 13l-1.686-1.7.713-.7.98.993 1.947-1.926zM2.667 2c-.74 0-1.334.593-1.334 1.333V6c0 .74.594 1.333 1.334 1.333h2.666c.74 0 1.334-.593 1.334-1.333V3.333C6.667 2.593 6.073 2 5.333 2H2.667zm0 1.333h2.666V6H2.667V3.333zm5.333 0h6.667v1.334H8V3.333zm0 9.334v-1.334h6.667v1.334H8zm0-5.334h6.667v1.334H8V7.333z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});