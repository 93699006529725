define("ember-svg-jar/inlined/icon-park-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M10 16.9999C13.8661 16.9999 17 13.866 17 9.99994C17 6.13384 13.8661 2.99994 10 2.99994C6.1339 2.99994 3 6.13384 3 9.99994C3 13.866 6.1339 16.9999 10 16.9999Z\" stroke-width=\"1.3\" stroke-linejoin=\"round\"/>\n<path d=\"M13.1501 8.94989L10.0001 12.0999L6.8501 8.94989\" stroke-width=\"1.3\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});