define("ember-svg-jar/inlined/document-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><clipPath id=\"a\"><path d=\"M0 960h1080V0H0z\"/></clipPath></defs><g clip-path=\"url(#a)\" transform=\"matrix(.35278 0 0 -.35278 -273.612 241.89)\"><path d=\"M789.593 672.155v-.97a.516.516 0 0 0-.515-.515h-8.97a.516.516 0 0 0-.515.515v.97c0 .284.23.515.515.515h8.97a.516.516 0 0 0 .515-.515m2 7.515h-4v4h.5l3.5-3.5zm0-12h-14v16h9v-2h-6c-.55 0-1-.45-1-1v-5c0-.55.45-1 1-1h8c.55 0 1 .45 1 1v3h2zm1.526 13.474l-4.052 4.052a1.62 1.62 0 0 1-1.145.474h-11.337a.992.992 0 0 1-.992-.992v-18.016c0-.548.444-.992.992-.992H792.6c.548 0 .992.444.992.992V680c0 .43-.171.842-.474 1.145\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 6.3499999 7.0555553",
      "height": "26.667",
      "width": "24"
    }
  };
  _exports.default = _default;
});