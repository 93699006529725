define("ember-svg-jar/inlined/logout-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M14.1667 5.83333L12.9917 7.00833L15.1417 9.16667H6.66675V10.8333H15.1417L12.9917 12.9833L14.1667 14.1667L18.3334 10L14.1667 5.83333ZM3.33341 4.16667H10.0001V2.5H3.33341C2.41675 2.5 1.66675 3.25 1.66675 4.16667V15.8333C1.66675 16.75 2.41675 17.5 3.33341 17.5H10.0001V15.8333H3.33341V4.16667Z\"/>\n",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});