define("ember-svg-jar/inlined/checkmark-rounded-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M18.612 6.833a1.38 1.538 0 0 0-1.867 0l-7.08 7.406-2.412-2.524a1.38 1.538 0 0 0-1.867 0 1.187 1.323 0 0 0 0 1.953l3.346 3.499a1.38 1.538 0 0 0 1.868 0l8.014-8.382a1.184 1.32 0 0 0 0-1.95\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});