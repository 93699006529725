define("ember-svg-jar/inlined/organization-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M16.5455 14.7272H18.3636V16.5454H16.5455V14.7272Z\" fill=\"#7C849B\"/>\n<path d=\"M18.3636 11.0908H16.5455V12.909H18.3636V11.0908Z\" fill=\"#7C849B\"/>\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.9091 7.45448H22V20.1818H2V3.81812H12.9091V7.45448ZM8.36364 5.6363H11.0909V7.45448H8.36364V5.6363ZM11.0909 18.3636V16.5454H8.36364V18.3636H11.0909ZM11.0909 14.7272V12.909H8.36364V14.7272H11.0909ZM11.0909 11.0908V9.27266H8.36364V11.0908H11.0909ZM20.1818 18.3636V9.27266H12.9091V11.0908H14.7273V12.909H12.9091V14.7272H14.7273V16.5454H12.9091V18.3636H20.1818ZM3.81818 18.3636V16.5454H6.54545V18.3636H3.81818ZM3.81818 14.7272H6.54545V12.909H3.81818V14.7272ZM6.54545 11.0908V9.27266H3.81818V11.0908H6.54545ZM3.81818 7.45448H6.54545V5.6363H3.81818V7.45448Z\" fill=\"#7C849B\"/>\n",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});