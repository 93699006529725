define("ember-svg-jar/inlined/lock-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.5715 9.33333H15.8095V7.80952C15.8095 5.70667 14.1029 4 12 4C9.89717 4 8.1905 5.70667 8.1905 7.80952V9.33333H7.42859C6.5905 9.33333 5.90479 10.019 5.90479 10.8571V18.4762C5.90479 19.3143 6.5905 20 7.42859 20H16.5715C17.4095 20 18.0953 19.3143 18.0953 18.4762V10.8571C18.0953 10.019 17.4095 9.33333 16.5715 9.33333ZM12 16.1905C11.1619 16.1905 10.4762 15.5048 10.4762 14.6667C10.4762 13.8286 11.1619 13.1429 12 13.1429C12.8381 13.1429 13.5238 13.8286 13.5238 14.6667C13.5238 15.5048 12.8381 16.1905 12 16.1905ZM9.71431 7.80952V9.33333H14.2857V7.80952C14.2857 6.54476 13.2648 5.52381 12 5.52381C10.7353 5.52381 9.71431 6.54476 9.71431 7.80952Z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});