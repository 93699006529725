define("ember-svg-jar/inlined/hours-changed-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.66675 18.3333L2.91675 17.0833L4.16675 18.3333L5.41675 17.0833L6.66675 18.3333L7.91675 17.0833L9.16675 18.3333L10.4167 17.0833L11.6667 18.3333L12.9167 17.0833L13.3334 17.5V2.49996L12.9167 2.91663L11.6667 1.66663L10.4167 2.91663L9.16675 1.66663L7.91675 2.91663L6.66675 1.66663L5.41675 2.91663L4.16675 1.66663L2.91675 2.91663L1.66675 1.66663V18.3333ZM8.33341 14.1666H6.66675V13.3333H5.00008V11.6666H8.33341V10.8333H5.83341C5.37508 10.8333 5.00008 10.4583 5.00008 9.99996V7.49996C5.00008 7.04163 5.37508 6.66663 5.83341 6.66663H6.66675V5.83329H8.33341V6.66663H10.0001V8.33329H6.66675V9.16663H9.16675C9.62508 9.16663 10.0001 9.54163 10.0001 9.99996V12.5C10.0001 12.9583 9.62508 13.3333 9.16675 13.3333H8.33341V14.1666Z\" fill=\"#364152\"/>\n<path d=\"M17.5001 15.8333H15.8334V17.5H17.5001V15.8333Z\" fill=\"#364152\"/>\n<path d=\"M17.5001 7.49996H15.8334V13.75H17.5001V7.49996Z\" fill=\"#364152\"/>\n",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});