define("ember-svg-jar/inlined/playlist-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M9.33325 5.99967H1.33325V7.33301H9.33325V5.99967ZM9.33325 3.33301H1.33325V4.66634H9.33325V3.33301ZM11.9999 9.99967V7.33301H10.6666V9.99967H7.99992V11.333H10.6666V13.9997H11.9999V11.333H14.6666V9.99967H11.9999ZM1.33325 9.99967H6.66659V8.66634H1.33325V9.99967Z\"/>\n",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});