define("ember-svg-jar/inlined/time-zone-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M3.552 8.688a4.506 4.506 0 0 0 2.87 3.528c-.437-.873-.713-2.12-.78-3.528h-2.09zm-.041-1h2.117c.034-1.561.318-2.955.794-3.904a4.503 4.503 0 0 0-2.911 3.904zm8.978 0a4.503 4.503 0 0 0-2.911-3.904c.476.95.76 2.343.794 3.904h2.117zm-.041 1h-2.09c-.067 1.408-.343 2.655-.78 3.528a4.506 4.506 0 0 0 2.87-3.528zm-5.804 0c.12 2.17.789 3.812 1.356 3.812.567 0 1.236-1.642 1.356-3.813H6.644zm-.015-1H9.37C9.312 5.333 8.6 3.5 8 3.5c-.6 0-1.312 1.834-1.371 4.188zM8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});