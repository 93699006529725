define("ember-svg-jar/inlined/block-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.99984 1.66663C5.39984 1.66663 1.6665 5.39996 1.6665 9.99996C1.6665 14.6 5.39984 18.3333 9.99984 18.3333C14.5998 18.3333 18.3332 14.6 18.3332 9.99996C18.3332 5.39996 14.5998 1.66663 9.99984 1.66663ZM3.33317 9.99996C3.33317 6.31663 6.3165 3.33329 9.99984 3.33329C11.5415 3.33329 12.9582 3.85829 14.0832 4.74163L4.7415 14.0833C3.85817 12.9583 3.33317 11.5416 3.33317 9.99996ZM5.9165 15.2583C7.0415 16.1416 8.45817 16.6666 9.99984 16.6666C13.6832 16.6666 16.6665 13.6833 16.6665 9.99996C16.6665 8.45829 16.1415 7.04163 15.2582 5.91663L5.9165 15.2583Z\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});