define("ember-svg-jar/inlined/list-ul-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M3.6 5.5h1.8a.6.6 0 0 1 .6.6v1.8a.6.6 0 0 1-.6.6H3.6a.6.6 0 0 1-.6-.6V6.1a.6.6 0 0 1 .6-.6zm0 5h1.8a.6.6 0 0 1 .6.6v1.8a.6.6 0 0 1-.6.6H3.6a.6.6 0 0 1-.6-.6v-1.8a.6.6 0 0 1 .6-.6zm0 5h1.8a.6.6 0 0 1 .6.6v1.8a.6.6 0 0 1-.6.6H3.6a.6.6 0 0 1-.6-.6v-1.8a.6.6 0 0 1 .6-.6zm5-10h11.8a.6.6 0 0 1 .6.6v1.8a.6.6 0 0 1-.6.6H8.6a.6.6 0 0 1-.6-.6V6.1a.6.6 0 0 1 .6-.6zm0 5h11.8a.6.6 0 0 1 .6.6v1.8a.6.6 0 0 1-.6.6H8.6a.6.6 0 0 1-.6-.6v-1.8a.6.6 0 0 1 .6-.6zm0 5h11.8a.6.6 0 0 1 .6.6v1.8a.6.6 0 0 1-.6.6H8.6a.6.6 0 0 1-.6-.6v-1.8a.6.6 0 0 1 .6-.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});