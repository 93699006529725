define("ember-svg-jar/inlined/task-with-list-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M3.33317 10.8333C2.40817 10.8333 1.6665 11.575 1.6665 12.5V15.8333C1.6665 16.7583 2.40817 17.5 3.33317 17.5H6.6665C7.5915 17.5 8.33317 16.7583 8.33317 15.8333V12.5C8.33317 11.575 7.5915 10.8333 6.6665 10.8333H3.33317ZM6.83317 12.0833L7.7165 12.9583L4.3915 16.25L2.28317 14.125L3.17484 13.25L4.39984 14.4917L6.83317 12.0833ZM3.33317 2.5C2.40817 2.5 1.6665 3.24167 1.6665 4.16667V7.5C1.6665 8.425 2.40817 9.16667 3.33317 9.16667H6.6665C7.5915 9.16667 8.33317 8.425 8.33317 7.5V4.16667C8.33317 3.24167 7.5915 2.5 6.6665 2.5H3.33317ZM3.33317 4.16667H6.6665V7.5H3.33317V4.16667ZM9.99984 4.16667H18.3332V5.83333H9.99984V4.16667ZM9.99984 15.8333V14.1667H18.3332V15.8333H9.99984ZM9.99984 9.16667H18.3332V10.8333H9.99984V9.16667Z\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});