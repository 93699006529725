define("ember-svg-jar/inlined/keyboard-double-arrow-right-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M4.8075 5.1001L3.75 6.1576L7.185 9.6001L3.75 13.0426L4.8075 14.1001L9.3075 9.6001L4.8075 5.1001Z\" fill=\"#697586\"/>\n<path d=\"M9.75 5.1001L8.6925 6.1576L12.1275 9.6001L8.6925 13.0426L9.75 14.1001L14.25 9.6001L9.75 5.1001Z\" fill=\"#697586\"/>\n",
    "attrs": {
      "width": "18",
      "height": "19",
      "viewBox": "0 0 18 19",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});