define("ember-svg-jar/inlined/image-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M21.1 19.078V4.922c0-1.112-.91-2.022-2.022-2.022H4.922C3.81 2.9 2.9 3.81 2.9 4.922v14.156c0 1.112.91 2.022 2.022 2.022h14.156c1.112 0 2.022-.91 2.022-2.022zM8.462 13.517l2.526 3.044L14.528 12l4.55 6.067H4.922z\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});