define("ember-svg-jar/inlined/calendar-today-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18.3 3.81818H19.2C20.19 3.81818 21 4.63636 21 5.63636V20.1818C21 21.1818 20.19 22 19.2 22H4.8C3.81 22 3 21.1818 3 20.1818V5.63636C3 4.63636 3.81 3.81818 4.8 3.81818H5.7V2H7.5V3.81818H16.5V2H18.3V3.81818ZM4.8 20.1818H19.2V8.36364H4.8V20.1818Z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});