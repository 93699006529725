define("ember-svg-jar/inlined/arrow-circle-left-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M3.343 4.7L1.727 3.375a.444.444 0 0 1-.163-.342c0-.132.06-.257.163-.341L3.343 1.37a.442.442 0 0 1 .558.684l-1.199.98 1.2.982a.441.441 0 0 1-.558.684m2.725-1.666A3.037 3.037 0 0 0 3.034 0 3.037 3.037 0 0 0 0 3.034 3.038 3.038 0 0 0 3.034 6.07 3.038 3.038 0 0 0 6.07 3.034\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 6.069 6.069"
    }
  };
  _exports.default = _default;
});