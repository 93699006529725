define("ember-svg-jar/inlined/hours-edited-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M16 2.34853H15.25V0.833374H13.75V2.34853H6.25V0.833374H4.75V2.34853H4C3.175 2.34853 2.5 3.03034 2.5 3.86368V15.9849C2.5 16.8182 3.175 17.5 4 17.5H12.5V15.9849H4V6.1364H13.3333V6.13285H17.5V3.86368C17.5 3.03034 16.825 2.34853 16 2.34853Z\" fill=\"#364152\"/>\n<path d=\"M15.8333 8.33337H17.5V13.75H15.8333V8.33337Z\" fill=\"#364152\"/>\n<path d=\"M15.8333 15.8334H17.5V17.5H15.8333V15.8334Z\" fill=\"#364152\"/>\n",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});