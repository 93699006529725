define("ember-svg-jar/inlined/restore-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2 8C2 4.68667 4.68667 2 8 2C11.3133 2 14 4.68667 14 8C14 11.3133 11.3133 14 8 14C6.62667 14 5.36 13.5333 4.34667 12.76L5.29333 11.8C6.06 12.34 6.99333 12.6667 8 12.6667C10.58 12.6667 12.6667 10.58 12.6667 8C12.6667 5.42 10.58 3.33333 8 3.33333C5.42 3.33333 3.33333 5.42 3.33333 8H5.33333L2.66667 10.6667L0 8H2ZM8 6.66667C8.73333 6.66667 9.33333 7.26667 9.33333 8C9.33333 8.73333 8.73333 9.33333 8 9.33333C7.26667 9.33333 6.66667 8.73333 6.66667 8C6.66667 7.26667 7.26667 6.66667 8 6.66667Z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});