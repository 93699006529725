define("ember-svg-jar/inlined/paid-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M10 1.6665C5.40002 1.6665 1.66669 5.39984 1.66669 9.99984C1.66669 14.5998 5.40002 18.3332 10 18.3332C14.6 18.3332 18.3334 14.5998 18.3334 9.99984C18.3334 5.39984 14.6 1.6665 10 1.6665ZM10.7334 14.7998V15.8332H9.27502V14.7582C8.65835 14.6082 7.28335 14.1165 6.75835 12.2915L8.13335 11.7332C8.18335 11.9165 8.61669 13.4748 10.1334 13.4748C10.9084 13.4748 11.7834 13.0748 11.7834 12.1332C11.7834 11.3332 11.2 10.9165 9.88335 10.4415C8.96669 10.1165 7.09169 9.58317 7.09169 7.68317C7.09169 7.59984 7.10002 5.68317 9.27502 5.2165V4.1665H10.7334V5.19984C12.2667 5.4665 12.825 6.6915 12.95 7.05817L11.6334 7.6165C11.5417 7.32484 11.1417 6.49984 10.05 6.49984C9.46669 6.49984 8.54169 6.80817 8.54169 7.65817C8.54169 8.44984 9.25835 8.74984 10.7417 9.2415C12.7417 9.93317 13.25 10.9498 13.25 12.1165C13.25 14.3082 11.1667 14.7248 10.7334 14.7998Z\" fill=\"#4B5565\"/>\n",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});