define("ember-svg-jar/inlined/connection-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M21.4 7.49999C22.2 8.29999 22.2 9.59999 21.4 10.3L18.6 13.1L10.8 5.29999L13.6 2.49999C14.4 1.69999 15.7 1.69999 16.4 2.49999L18.2 4.29999L21.2 1.29999L22.6 2.69999L19.6 5.69999L21.4 7.49999ZM15.6 13.3L14.2 11.9L11.4 14.7L9.30002 12.6L12.1 9.79999L10.7 8.39999L7.90002 11.2L6.40002 9.79999L3.60002 12.6C2.80002 13.4 2.80002 14.7 3.60002 15.4L5.40002 17.2L1.40002 21.2L2.80002 22.6L6.80002 18.6L8.60002 20.4C9.40002 21.2 10.7 21.2 11.4 20.4L14.2 17.6L12.8 16.2L15.6 13.3Z\"/>\n",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});