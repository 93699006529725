define("ember-cropperjs/components/image-cropper-call", ["exports", "@ember/debug", "@ember/component", "@ember/object"], function (_exports, _debug, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ImageCropperCallComponent = _component.default.extend({
    tagName: '',
    obj: null,
    func: null,
    args: null,
    onResp: null,
    params: null,
    didReceiveAttrs() {
      this._super(...arguments);
      let {
        obj,
        func,
        args,
        params
      } = (0, _object.getProperties)(this, 'obj', 'func', 'args', 'params');
      if (args === null && params !== null) {
        if (func !== null) {
          args = params;
        } else {
          [func, ...args] = params;
        }
      }
      (false && !(typeof obj === 'object' && obj !== null) && (0, _debug.assert)('image-cropper-call obj is required', typeof obj === 'object' && obj !== null));
      (false && !(typeof func === 'string') && (0, _debug.assert)('image-cropper-call func is required and must be a string', typeof func === 'string'));
      (false && !(typeof obj[func] === 'function') && (0, _debug.assert)(`image-cropper-call ${func} must be a function on ${obj}`, typeof obj[func] === 'function'));
      this.sendAction('onResp', obj[func].apply(obj, args)); // eslint-disable-line ember/closure-actions
    }
  });

  ImageCropperCallComponent.reopenClass({
    positionalParams: 'params'
  });
  var _default = ImageCropperCallComponent;
  _exports.default = _default;
});