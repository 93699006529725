define("ember-svg-jar/inlined/schedule-filled-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<rect width=\"16\" height=\"16\" rx=\"8\" fill=\"#F6A931\"/>\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3 8C3 5.24 5.235 3 7.995 3C10.76 3 13 5.24 13 8C13 10.76 10.76 13 7.995 13C5.235 13 3 10.76 3 8ZM7.5 5.5H8.25V8.125L10.5 9.46L10.125 10.075L7.5 8.5V5.5Z\" fill=\"white\"/>\n",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});