define("ember-svg-jar/inlined/disturb-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.33203 7.99992C1.33203 4.31992 4.3187 1.33325 7.9987 1.33325C11.6787 1.33325 14.6654 4.31992 14.6654 7.99992C14.6654 11.6799 11.6787 14.6666 7.9987 14.6666C4.3187 14.6666 1.33203 11.6799 1.33203 7.99992ZM4.66536 8.66659H11.332V7.33325H4.66536V8.66659Z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});