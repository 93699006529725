define("ember-svg-jar/inlined/icon-taxable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.00065 1.33325C11.6807 1.33325 14.6673 4.31992 14.6673 7.99992C14.6673 11.6799 11.6807 14.6666 8.00065 14.6666C4.32065 14.6666 1.33398 11.6799 1.33398 7.99992C1.33398 4.31992 4.32065 1.33325 8.00065 1.33325ZM5.0944 7.15617C5.37912 7.44089 5.72287 7.58325 6.12565 7.58325C6.52843 7.58325 6.87218 7.44089 7.1569 7.15617C7.44162 6.87145 7.58398 6.5277 7.58398 6.12492C7.58398 5.72214 7.44162 5.37839 7.1569 5.09367C6.87218 4.80895 6.52843 4.66659 6.12565 4.66659C5.72287 4.66659 5.37912 4.80895 5.0944 5.09367C4.80968 5.37839 4.66732 5.72214 4.66732 6.12492C4.66732 6.5277 4.80968 6.87145 5.0944 7.15617ZM6.56815 6.56742C6.44676 6.68909 6.29926 6.74992 6.12565 6.74992C5.95204 6.74992 5.80454 6.68909 5.68315 6.56742C5.56148 6.44603 5.50065 6.29853 5.50065 6.12492C5.50065 5.95131 5.56148 5.80381 5.68315 5.68242C5.80454 5.56075 5.95204 5.49992 6.12565 5.49992C6.29926 5.49992 6.44676 5.56075 6.56815 5.68242C6.68982 5.80381 6.75065 5.95131 6.75065 6.12492C6.75065 6.29853 6.68982 6.44603 6.56815 6.56742ZM8.8444 10.9062C9.12912 11.1909 9.47287 11.3333 9.87565 11.3333C10.2784 11.3333 10.6222 11.1909 10.9069 10.9062C11.1916 10.6214 11.334 10.2777 11.334 9.87492C11.334 9.47214 11.1916 9.12839 10.9069 8.84367C10.6222 8.55895 10.2784 8.41659 9.87565 8.41659C9.47287 8.41659 9.12912 8.55895 8.8444 8.84367C8.55968 9.12839 8.41732 9.47214 8.41732 9.87492C8.41732 10.2777 8.55968 10.6214 8.8444 10.9062ZM10.3182 10.3174C10.1968 10.4391 10.0493 10.4999 9.87565 10.4999C9.70204 10.4999 9.55454 10.4391 9.43315 10.3174C9.31148 10.196 9.25065 10.0485 9.25065 9.87492C9.25065 9.70131 9.31148 9.55381 9.43315 9.43242C9.55454 9.31075 9.70204 9.24992 9.87565 9.24992C10.0493 9.24992 10.1968 9.31075 10.3182 9.43242C10.4398 9.55381 10.5007 9.70131 10.5007 9.87492C10.5007 10.0485 10.4398 10.196 10.3182 10.3174ZM4.66732 10.7499L5.25065 11.3333L11.334 5.24992L10.7507 4.66659L4.66732 10.7499Z\" fill=\"#7C849B\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});