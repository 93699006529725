define("ember-svg-jar/inlined/reminder-on", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.4 1.92999L22 5.78999L20.71 7.31999L16.11 3.45999L17.4 1.92999ZM6.6 1.92999L7.88 3.45999L3.29 7.30999L2 5.77999L6.6 1.92999ZM3 13.07C3 8.09999 7.03 4.06999 12 4.06999C16.97 4.06999 21 8.09999 21 13.07C21 18.04 16.97 22.07 12 22.07C7.02 22.07 3 18.04 3 13.07ZM12.5 8.07001H11V14.07L15.75 16.92L16.5 15.69L12.5 13.32V8.07001Z\" fill=\"#1570EF\"/>\n",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});