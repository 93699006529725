define("ember-svg-jar/inlined/file-document-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M4.99967 1.66797C4.55765 1.66797 4.13372 1.84356 3.82116 2.15612C3.5086 2.46868 3.33301 2.89261 3.33301 3.33464V16.668C3.33301 17.11 3.5086 17.5339 3.82116 17.8465C4.13372 18.159 4.55765 18.3346 4.99967 18.3346H14.9997C15.4417 18.3346 15.8656 18.159 16.1782 17.8465C16.4907 17.5339 16.6663 17.11 16.6663 16.668V6.66797L11.6663 1.66797H4.99967ZM4.99967 3.33464H10.833V7.5013H14.9997V16.668H4.99967V3.33464ZM6.66634 10.0013V11.668H13.333V10.0013H6.66634ZM6.66634 13.3346V15.0013H10.833V13.3346H6.66634Z\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});