define("ember-svg-jar/inlined/trending-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.6667 4L12.1933 5.52667L8.94 8.78L6.27333 6.11333L1.33333 11.06L2.27333 12L6.27333 8L8.94 10.6667L13.14 6.47333L14.6667 8V4H10.6667Z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});