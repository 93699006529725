define("ember-svg-jar/inlined/sort-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n  <path d=\"M4.3335 7.33332L8.3335 2.66666L12.3335 7.33332H4.3335Z\" fill=\"#c8c7c9\"/>\n  <path d=\"M4.3335 9.33334L8.3335 14L12.3335 9.33334H4.3335Z\" fill=\"#c8c7c9\"/>\n",
    "attrs": {
      "width": "17",
      "height": "16",
      "viewBox": "0 0 17 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});