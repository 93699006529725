define("ember-svg-jar/inlined/reload-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17.65 6.35C16.2 4.9 14.21 4 12 4C7.57999 4 4.00999 7.58 4.00999 12C4.00999 16.42 7.57999 20 12 20C15.73 20 18.84 17.45 19.73 14H17.65C16.83 16.33 14.61 18 12 18C8.68999 18 5.99999 15.31 5.99999 12C5.99999 8.69 8.68999 6 12 6C13.66 6 15.14 6.69 16.22 7.78L13 11H20V4L17.65 6.35Z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});