define("ember-svg-jar/inlined/expand-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4 3V1H20V3H4ZM11 8H8L12 4L16 8H13V12V16H16L12 20L8 16H11V12V8ZM4 21V23H20V21H4Z\" fill=\"#7C849B\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});