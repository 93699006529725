define("ember-svg-jar/inlined/timer-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.5 0.833374H12.5V2.50004H7.5V0.833374ZM9.16667 11.6667V6.66671H10.8333V11.6667H9.16667ZM15.8583 6.15837L17.0417 4.97504C16.6833 4.55004 16.2917 4.15004 15.8667 3.80004L14.6833 4.98337C13.3917 3.95004 11.7667 3.33337 10 3.33337C5.85833 3.33337 2.5 6.69171 2.5 10.8334C2.5 14.975 5.85 18.3334 10 18.3334C14.15 18.3334 17.5 14.975 17.5 10.8334C17.5 9.06671 16.8833 7.44171 15.8583 6.15837ZM4.16667 10.8334C4.16667 14.0584 6.775 16.6667 10 16.6667C13.225 16.6667 15.8333 14.0584 15.8333 10.8334C15.8333 7.60837 13.225 5.00004 10 5.00004C6.775 5.00004 4.16667 7.60837 4.16667 10.8334Z\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});