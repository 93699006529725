define("ember-svg-jar/inlined/checkbox-square-on", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.19444 1.25H16.8056C17.8847 1.25 18.75 2.125 18.75 3.19444V16.8056C18.75 17.875 17.8847 18.75 16.8056 18.75H3.19444C2.11528 18.75 1.25 17.875 1.25 16.8056V3.19444C1.25 2.125 2.11528 1.25 3.19444 1.25ZM3.19444 10L8.05556 14.8611L16.8056 6.11111L15.4347 4.73056L8.05556 12.1097L4.56528 8.62917L3.19444 10Z\" fill=\"#0B80F3\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});