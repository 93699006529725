define("ember-svg-jar/inlined/password-eye-crossed-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2.01 3.87L3.42 2.45L21.15 20.2L19.74 21.61L16.31 18.18C14.97 18.7 13.52 19 12 19C7 19 2.73 15.89 1 11.5C1.77 9.53 3.06 7.83 4.69 6.55L2.01 3.87ZM17 11.5C17 8.74 14.76 6.5 12 6.5C11.49 6.5 11 6.6 10.53 6.74L8.36 4.57C9.51 4.2 10.73 4 12 4C17 4 21.27 7.11 23 11.49C22.31 13.25 21.21 14.79 19.82 16.02L16.76 12.96C16.9 12.5 17 12.01 17 11.5ZM12 16.5C9.24 16.5 7 14.26 7 11.5C7 10.73 7.18 10 7.49 9.36L9.06 10.93C9.03 11.11 9 11.3 9 11.5C9 13.16 10.34 14.5 12 14.5C12.2 14.5 12.38 14.47 12.57 14.43L14.14 16C13.49 16.32 12.77 16.5 12 16.5ZM14.97 11.17C14.82 9.77 13.72 8.68 12.33 8.53L14.97 11.17Z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});