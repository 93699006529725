define("ember-svg-jar/inlined/checkbox-square-minus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5 10.9375H15V9.0625H5V10.9375Z\" fill=\"white\"/>\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.19444 1.25H16.8056C17.8847 1.25 18.75 2.125 18.75 3.19444V16.8056C18.75 17.875 17.8847 18.75 16.8056 18.75H3.19444C2.11528 18.75 1.25 17.875 1.25 16.8056V3.19444C1.25 2.125 2.11528 1.25 3.19444 1.25ZM15 10.9375H5V9.0625H15V10.9375Z\" fill=\"#0B80F3\"/>\n",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});