define("ember-svg-jar/inlined/assignment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M14.25 3H11.115C10.8 2.13 9.975 1.5 9 1.5C8.025 1.5 7.2 2.13 6.885 3H3.75C2.925 3 2.25 3.675 2.25 4.5V15C2.25 15.825 2.925 16.5 3.75 16.5H14.25C15.075 16.5 15.75 15.825 15.75 15V4.5C15.75 3.675 15.075 3 14.25 3ZM9 3C9.4125 3 9.75 3.3375 9.75 3.75C9.75 4.1625 9.4125 4.5 9 4.5C8.5875 4.5 8.25 4.1625 8.25 3.75C8.25 3.3375 8.5875 3 9 3ZM10.5 13.5H5.25V12H10.5V13.5ZM12.75 10.5H5.25V9H12.75V10.5ZM12.75 7.5H5.25V6H12.75V7.5Z\" fill=\"#4B5565\"/>\n",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});