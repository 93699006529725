define("ember-svg-jar/inlined/desktop-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2.00002 1.83325H14C14.7334 1.83325 15.3334 2.43325 15.3334 3.16659V11.1666C15.3334 11.8999 14.7334 12.4999 14 12.4999H9.33335L10.6667 14.4999V15.1666H5.33335V14.4999L6.66669 12.4999H2.00002C1.26669 12.4999 0.666687 11.8999 0.666687 11.1666V3.16659C0.666687 2.43325 1.26669 1.83325 2.00002 1.83325ZM2 9.83324H14V3.16658H2V9.83324Z\"/>",
    "attrs": {
      "viewBox": "0 0 16 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});