define("ember-svg-jar/inlined/search-clear-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M1.587 0a1.588 1.588 0 1 0 0 3.175 1.588 1.588 0 0 0 0-3.175zm.68 2.015l-.252.252s-.4-.429-.43-.429c-.03 0-.429.429-.429.429l-.252-.252s.429-.394.429-.429c0-.036-.43-.43-.43-.43l.253-.252s.403.429.43.429c.026 0 .429-.429.429-.429l.252.253s-.43.4-.43.43c0 .027.43.428.43.428z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 3.175 3.175"
    }
  };
  _exports.default = _default;
});