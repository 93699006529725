define("ember-svg-jar/inlined/task-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M18 4.544L8.872 13.68L5.48 10.288L6.608 9.16L8.872 11.424L16.872 3.424L18 4.544ZM16.232 8.576C16.336 9.032 16.4 9.512 16.4 10C16.4 13.536 13.536 16.4 10 16.4C6.464 16.4 3.6 13.536 3.6 10C3.6 6.464 6.464 3.6 10 3.6C11.264 3.6 12.432 3.968 13.424 4.6L14.576 3.448C13.28 2.536 11.704 2 10 2C5.584 2 2 5.584 2 10C2 14.416 5.584 18 10 18C14.416 18 18 14.416 18 10C18 9.048 17.824 8.136 17.52 7.288L16.232 8.576Z\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});