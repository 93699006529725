define("ember-svg-jar/inlined/arrow-up-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M11.6334 11.7L8.00003 15.3333L4.3667 11.7L5.3067 10.76L7.33337 12.78V3.21996L5.3067 5.23996L4.3667 4.29996L8.00003 0.666626L11.6334 4.29996L10.6934 5.23996L8.6667 3.21996V12.78L10.6934 10.76L11.6334 11.7Z\" fill=\"#7C849B\"/>\n",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});