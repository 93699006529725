define("ember-svg-jar/inlined/check-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M16.5 7.5L12 3H3C2.175 3 1.5 3.675 1.5 4.5V13.5075C1.5 14.3325 2.175 15 3 15L15 14.9925C15.825 14.9925 16.5 14.325 16.5 13.5V7.5ZM11.25 4.125L15.375 8.25H11.25V4.125Z\" fill=\"#4B5565\"/>\n",
    "attrs": {
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});