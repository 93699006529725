define("ember-place-autocomplete/components/place-autocomplete", ["exports", "@ember/component", "@glimmer/component", "@ember/error", "@ember/object", "@ember/application", "@ember/object/internals", "@ember/runloop", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _error, _object, _application, _internals, _runloop, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <input
    type='text'
    class='place-autocomplete'
    value={{this.value}}
    ...attributes
    {{on 'input' this._updateValue}}
    {{did-insert this._initialize}}
    {{did-update this._update this._options}}
    {{will-destroy this._destroy}}
  />
  */
  {
    "id": "5h2WcGMa",
    "block": "[[[11,\"input\"],[24,4,\"text\"],[24,0,\"place-autocomplete\"],[16,2,[30,0,[\"value\"]]],[17,1],[4,[38,0],[\"input\",[30,0,[\"_updateValue\"]]],null],[4,[38,1],[[30,0,[\"_initialize\"]]],null],[4,[38,2],[[30,0,[\"_update\"]],[30,0,[\"_options\"]]],null],[4,[38,3],[[30,0,[\"_destroy\"]]],null],[12],[13]],[\"&attrs\"],false,[\"on\",\"did-insert\",\"did-update\",\"will-destroy\"]]",
    "moduleName": "ember-place-autocomplete/components/place-autocomplete.hbs",
    "isStrictMode": false
  });
  /* globals google */
  let PlaceAutocompleteComponent = (_class = class PlaceAutocompleteComponent extends _component2.default {
    get config() {
      const _config = (0, _application.getOwner)(this).resolveRegistration('config:environment') || {};
      return _config['ember-place-autocomplete'] || {};
    }
    get _options() {
      const options = {};
      Object.keys(this.args).forEach(option => {
        const _option = this.args[option];
        if (typeof _option === 'object') {
          options[option] = Object.assign({}, _option);
        } else {
          options[option] = _option;
        }
      });
      if (this.args.types) {
        options.types = [...this.args.types];
      }
      if (this.args.placeIdOnly) {
        delete options.placeIdOnly;
        options.fields = ['name', 'place_id'];
      } else if (this.args.fields) {
        options.fields = [...this.args.fields];
      }
      return options;
    }
    constructor() {
      super(...arguments);
      _defineProperty(this, "elementId", (0, _internals.guidFor)(this));
      _initializerDefineProperty(this, "value", _descriptor, this);
      _initializerDefineProperty(this, "intervalCount", _descriptor2, this);
      this.value = this.args.value;
      this.intervalCount = 0;
    }
    _initialize(element) {
      this.element = element;
      this._render();
    }
    _update() {
      if (this.autocomplete) {
        this.autocomplete.setOptions(this._options);
      }
    }
    _destroy() {
      if (this.autocomplete) {
        google.maps.event.clearInstanceListeners(this.autocomplete);
      }
      document.querySelector('.pac-container')?.remove();
    }
    _updateValue(event) {
      const _value = event.target.value;
      this.value = _value;
      this.args.onChange?.(_value);
    }
    _render() {
      if (google && google.maps && google.maps.places) {
        this.autocomplete = new google.maps.places.Autocomplete(this.element, this._options);
        this.autocomplete.addListener('place_changed', () => {
          this.placeChanged();
        });
        this.onRenderCallback();
      } else {
        if (this.intervalCount < 1000) {
          this.intervalCount++;
          (0, _runloop.later)(this, '_render', 100);
        } else {
          throw new _error.default("We tried 1000 times but no luck. We couldn't load the google.maps.places api.");
        }
      }
    }
    placeChanged() {
      if (this.autocomplete) {
        const place = this.autocomplete.getPlace();
        if (this.args.onSelect && typeof this.args.onSelect === 'function') {
          this.args.onSelect(place);
        }
      }
    }
    onRenderCallback() {
      if (this.args.onRender && typeof this.args.onRender === 'function') {
        this.args.onRender(this);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intervalCount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "_initialize", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_initialize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_update", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_update"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_destroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_destroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_updateValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_updateValue"), _class.prototype)), _class);
  _exports.default = PlaceAutocompleteComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PlaceAutocompleteComponent);
});