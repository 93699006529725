define("ember-svg-jar/inlined/calendar-o-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g transform=\"translate(4 5)\" fill-rule=\"evenodd\"><path d=\"M0 3.166C0 1.871 1.061.824 2.378.824h11.244C14.936.824 16 1.872 16 3.166v8.491C16 12.952 14.939 14 13.622 14H2.378C1.064 14 0 12.951 0 11.657v-8.49zm14.703 1.695H1.297V3.166c0-.592.482-1.067 1.081-1.067h11.244c.597 0 1.08.477 1.08 1.067v1.695zm0 1.276v5.52c0 .592-.482 1.068-1.081 1.068H2.378a1.074 1.074 0 0 1-1.08-1.068v-5.52zM3.243 8.262h2.595v2.55H3.243z\"/><rect width=\"1.684\" height=\"3.294\" x=\"4.211\" rx=\".842\"/><rect width=\"1.684\" height=\"3.294\" x=\"10.105\" rx=\".842\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});