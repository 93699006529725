define("ember-svg-jar/inlined/settings-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M19.5008 12C19.5008 12.34 19.4708 12.66 19.4308 12.98L21.5408 14.63C21.7308 14.78 21.7808 15.05 21.6608 15.27L19.6608 18.73C19.5408 18.95 19.2808 19.04 19.0508 18.95L16.5608 17.95C16.0408 18.34 15.4808 18.68 14.8708 18.93L14.4908 21.58C14.4608 21.82 14.2508 22 14.0008 22H10.0008C9.75082 22 9.54082 21.82 9.51082 21.58L9.13082 18.93C8.52082 18.68 7.96082 18.35 7.44082 17.95L4.95082 18.95C4.73082 19.03 4.46082 18.95 4.34082 18.73L2.34082 15.27C2.22082 15.05 2.27082 14.78 2.46082 14.63L4.57082 12.98C4.53082 12.66 4.50082 12.33 4.50082 12C4.50082 11.67 4.53082 11.34 4.57082 11.02L2.46082 9.37C2.27082 9.22 2.21082 8.95 2.34082 8.73L4.34082 5.27C4.46082 5.05 4.72082 4.96 4.95082 5.05L7.44082 6.05C7.96082 5.66 8.52082 5.32 9.13082 5.07L9.51082 2.42C9.54082 2.18 9.75082 2 10.0008 2H14.0008C14.2508 2 14.4608 2.18 14.4908 2.42L14.8708 5.07C15.4808 5.32 16.0408 5.65 16.5608 6.05L19.0508 5.05C19.2708 4.97 19.5408 5.05 19.6608 5.27L21.6608 8.73C21.7808 8.95 21.7308 9.22 21.5408 9.37L19.4308 11.02C19.4708 11.34 19.5008 11.66 19.5008 12ZM8.50082 12C8.50082 13.93 10.0708 15.5 12.0008 15.5C13.9308 15.5 15.5008 13.93 15.5008 12C15.5008 10.07 13.9308 8.5 12.0008 8.5C10.0708 8.5 8.50082 10.07 8.50082 12Z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});