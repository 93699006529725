define("ember-svg-jar/inlined/arrow-left-dark-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<g>\n<rect width=\"32\" height=\"32\" rx=\"16\" fill=\"#1F2021\" fill-opacity=\"0.7\"/>\n<path d=\"M19.6734 10.58L18.4867 9.40001L11.8934 16L18.4934 22.6L19.6734 21.42L14.2534 16L19.6734 10.58Z\" fill=\"white\"/>\n</g>\n<defs>\n<filter id=\"filter0_b_146_2586\" x=\"-20\" y=\"-20\" width=\"72\" height=\"72\" filterUnits=\"userSpaceOnUse\" color-interpolation-filters=\"sRGB\">\n<feFlood flood-opacity=\"0\" result=\"BackgroundImageFix\"/>\n<feGaussianBlur in=\"BackgroundImage\" stdDeviation=\"10\"/>\n<feComposite in2=\"SourceAlpha\" operator=\"in\" result=\"effect1_backgroundBlur_146_2586\"/>\n<feBlend mode=\"normal\" in=\"SourceGraphic\" in2=\"effect1_backgroundBlur_146_2586\" result=\"shape\"/>\n</filter>\n</defs>\n",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});