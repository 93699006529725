define("ember-svg-jar/inlined/tag-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16 5C16.67 5 17.27 5.33 17.63 5.84L22 12L17.63 18.16C17.27 18.67 16.67 19 16 19L5 18.99C3.9 18.99 3 18.1 3 17V7C3 5.9 3.9 5.01 5 5.01L16 5ZM5 17H16L19.55 12L16 7H5V17Z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});