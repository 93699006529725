define("ember-svg-jar/inlined/lock-open-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14.9998 0.833252C12.6998 0.833252 10.8332 2.69992 10.8332 4.99992V6.66658H3.33317C2.4165 6.66658 1.6665 7.40825 1.6665 8.33325V16.6666C1.6665 17.5916 2.4165 18.3333 3.33317 18.3333H13.3332C14.2582 18.3333 14.9998 17.5916 14.9998 16.6666V8.33325C14.9998 7.41658 14.2582 6.66658 13.3332 6.66658H12.4998V4.99992C12.4998 3.61659 13.6165 2.49992 14.9998 2.49992C16.3832 2.49992 17.4998 3.61659 17.4998 4.99992V6.66658H19.1665V4.99992C19.1665 2.69992 17.2998 0.833252 14.9998 0.833252ZM8.33317 10.8333C9.24984 10.8333 9.99984 11.5749 9.99984 12.4999C9.99984 13.4249 9.25817 14.1666 8.33317 14.1666C7.4165 14.1666 6.6665 13.4249 6.6665 12.4999C6.6665 11.5833 7.4165 10.8333 8.33317 10.8333Z\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});