define("ember-svg-jar/inlined/icon-cost", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.6673 7.99992C14.6673 4.31992 11.6807 1.33325 8.00065 1.33325C4.32065 1.33325 1.33398 4.31992 1.33398 7.99992C1.33398 11.6799 4.32065 14.6666 8.00065 14.6666C11.6807 14.6666 14.6673 11.6799 14.6673 7.99992ZM8.70574 11.9998V11.0449C9.57574 10.8799 10.2607 10.3799 10.2657 9.46486C10.2657 8.20487 9.18574 7.76988 8.17574 7.50988C7.16575 7.24988 6.84075 6.97488 6.84075 6.55488C6.84075 6.07489 7.29075 5.73489 8.04074 5.73489C8.83074 5.73489 9.12574 6.11489 9.15074 6.66988H10.1307C10.1007 5.90489 9.63573 5.19989 8.70574 4.97489V3.9999H7.37075V4.96489C6.51075 5.14989 5.81576 5.70989 5.81576 6.56988C5.81576 7.59488 6.66575 8.10487 7.90575 8.40487C9.02074 8.66987 9.24074 9.05987 9.24074 9.47486C9.24074 9.77986 9.02074 10.2699 8.04074 10.2699C7.12575 10.2699 6.76575 9.85986 6.71575 9.33487H5.73576C5.79076 10.3049 6.51575 10.8549 7.37075 11.0349V11.9998H8.70574Z\" fill=\"#7C849B\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});