define("ember-svg-jar/inlined/arrow-to-bottom-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5.229 2.716l-.558-.549a.315.315 0 0 0-.445.004l-.86.873V.314A.315.315 0 0 0 3.053 0H2.27a.314.314 0 0 0-.314.315v2.73l-.86-.874a.315.315 0 0 0-.445-.004l-.557.549a.315.315 0 0 0-.004.445l2.347 2.384a.315.315 0 0 0 .448 0l2.347-2.384a.315.315 0 0 0-.003-.445M5.132 6.67v.072a.317.317 0 0 1-.317.317H.509a.317.317 0 0 1-.316-.317V6.67c0-.175.141-.316.316-.316h4.306c.175 0 .317.141.317.316\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 5.322 7.059"
    }
  };
  _exports.default = _default;
});