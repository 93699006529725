define("ember-svg-jar/inlined/inventory-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M19.7778 2H4.22222C3 2 2 3 2 4.22222V12C2 13.2222 3 14.2222 4.22222 14.2222H19.7778C21 14.2222 22 13.2222 22 12V4.22222C22 3 21 2 19.7778 2ZM19.7778 8.66667H15.3333C15.3333 10.4667 13.8 12 12 12C10.2 12 8.66667 10.4667 8.66667 8.66667H4.22222V4.22222H19.7778V8.66667ZM22 16.4444H15.3333C15.3333 18.2889 13.8444 19.7778 12 19.7778C10.1556 19.7778 8.66667 18.2889 8.66667 16.4444H2V19.7778C2 21 3 22 4.22222 22H19.7778C21 22 22 21 22 19.7778V16.4444Z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});