define("ember-svg-jar/inlined/bullhorn-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.4998 4.00016C16.6665 4.22308 16.8332 4.446 16.9998 4.66683C16.4404 5.09067 15.7813 5.58349 15.1654 6.04394L15.1654 6.04396L15.1645 6.04466L15.1632 6.04561C14.8717 6.26355 14.59 6.47421 14.3332 6.66683C14.1665 6.446 13.9998 6.22308 13.8332 6.00016L13.8331 6.00009C13.6665 5.7772 13.4998 5.5543 13.3332 5.3335C13.5906 5.14043 13.873 4.92838 14.1652 4.70902L14.1655 4.70885C14.7813 4.24652 15.4404 3.75168 15.9998 3.3335C16.1665 3.55433 16.3332 3.77724 16.4998 4.00016ZM14.9998 9.16683V10.8335H18.3332V9.16683H14.9998ZM15.235 16.0943L15.2351 16.0944C15.5008 16.2934 15.7595 16.4872 15.9998 16.6668C16.1665 16.446 16.3332 16.2231 16.4998 16.0002C16.6665 15.7772 16.8332 15.5543 16.9998 15.3335C16.4411 14.9158 15.7829 14.4217 15.1678 13.9599L15.1655 13.9582L15.1654 13.9581C14.8731 13.7387 14.5906 13.5266 14.3332 13.3335C13.9998 13.7835 13.6665 14.2335 13.3332 14.6752C13.9 15.0944 14.5882 15.6098 15.2347 16.0941L15.235 16.0943ZM3.33317 7.50016C2.4165 7.50016 1.6665 8.25016 1.6665 9.16683V10.8335C1.6665 11.7502 2.4165 12.5002 3.33317 12.5002H4.1665V15.8335H5.83317V12.5002H6.6665L10.8332 15.0002V5.00016L6.6665 7.50016H3.33317ZM11.6665 7.2085C12.4332 7.89183 12.9165 8.89183 12.9165 10.0002C12.9165 11.1085 12.4332 12.1085 11.6665 12.7835V7.2085Z\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});