define("ember-svg-jar/inlined/placeholder-no-appointment-hours-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<rect x=\"0.5\" width=\"136\" height=\"136\" rx=\"32\" fill=\"#EBEFF2\"/>\n<path d=\"M89.5 41H86.5V35H80.5V41H56.5V35H50.5V41H47.5C44.17 41 41.53 43.7 41.53 47L41.5 89C41.5 92.3 44.17 95 47.5 95H89.5C92.8 95 95.5 92.3 95.5 89V47C95.5 43.7 92.8 41 89.5 41ZM89.5 89H47.5V56H89.5V89ZM53.5 62H68.5V77H53.5V62Z\" fill=\"white\"/>\n",
    "attrs": {
      "width": "137",
      "height": "136",
      "viewBox": "0 0 137 136",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});