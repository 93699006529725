define("ember-svg-jar/inlined/clone-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M15.1818 4H6.45455C5.65455 4 5 4.65455 5 5.45455V15.6364H6.45455V5.45455H15.1818V4Z\"/>\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.3636 6.90909H9.36364C8.56364 6.90909 7.90909 7.56364 7.90909 8.36364V18.5455C7.90909 19.3455 8.56364 20 9.36364 20H17.3636C18.1636 20 18.8182 19.3455 18.8182 18.5455V8.36364C18.8182 7.56364 18.1636 6.90909 17.3636 6.90909ZM9.36364 18.5455H17.3636V8.36364H9.36364V18.5455Z\"/>\n",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});