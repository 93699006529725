define("ember-svg-jar/inlined/dollar-symbol-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3 13.667l-1 1V1.333l1 1 1-1 1 1 1-1 1 1 1-1 1 1 1-1 1 1 1-1 1 1 1-1v13.334l-1-1-1 1-1-1-1 1-1-1-1 1-1-1-1 1-1-1-1 1-1-1zM8.705 12v-.955c.87-.165 1.555-.665 1.56-1.58 0-1.26-1.08-1.695-2.09-1.955-1.01-.26-1.335-.535-1.335-.955 0-.48.45-.82 1.2-.82.79 0 1.085.38 1.11.935h.98c-.03-.765-.495-1.47-1.425-1.695V4H7.37v.965c-.86.185-1.555.745-1.555 1.605 0 1.025.85 1.535 2.09 1.835 1.115.265 1.335.655 1.335 1.07 0 .305-.22.795-1.2.795-.915 0-1.275-.41-1.325-.935h-.98c.055.97.78 1.52 1.635 1.7V12h1.335z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});