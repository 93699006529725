define("ember-svg-jar/inlined/customer-filled-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M8 8C7.175 8 6.46875 7.70625 5.88125 7.11875C5.29375 6.53125 5 5.825 5 5C5 4.175 5.29375 3.46875 5.88125 2.88125C6.46875 2.29375 7.175 2 8 2C8.825 2 9.53125 2.29375 10.1187 2.88125C10.7063 3.46875 11 4.175 11 5C11 5.825 10.7063 6.53125 10.1187 7.11875C9.53125 7.70625 8.825 8 8 8ZM2 14V11.9C2 11.475 2.1095 11.0845 2.3285 10.7285C2.5475 10.3725 2.838 10.1005 3.2 9.9125C3.975 9.525 4.7625 9.2345 5.5625 9.041C6.3625 8.8475 7.175 8.7505 8 8.75C8.825 8.75 9.6375 8.847 10.4375 9.041C11.2375 9.235 12.025 9.5255 12.8 9.9125C13.1625 10.1 13.4532 10.372 13.6722 10.7285C13.8912 11.085 14.0005 11.4755 14 11.9V14H2Z\" fill=\"black\"/>\n",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});