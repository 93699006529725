define("ember-svg-jar/inlined/status-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12.9771 2V2.02003V4.02303C17.378 4.56385 20.4957 8.55984 19.9544 12.9564C19.4932 16.6019 16.6262 19.4962 12.9771 19.9269V21.9299C18.4908 21.3791 22.5007 16.4917 21.9493 10.9835C21.4982 6.22634 17.7189 2.47071 12.9771 2ZM10.9722 2.03005C9.01735 2.22033 7.15274 2.97146 5.62897 4.23335L7.06252 5.71557C8.18529 4.81422 9.53864 4.23335 10.9722 4.03305V2.03005ZM4.21548 5.64547C2.95235 7.16775 2.2005 9.02053 2 10.9835H4.00496C4.19543 9.56134 4.75682 8.20931 5.64902 7.07762L4.21548 5.64547ZM15.4833 8.47972L10.5912 13.3671L8.46598 11.2439L7.40336 12.3055L10.5912 15.4902L16.546 9.54131L15.4833 8.47972ZM2.01002 12.9865C2.21052 14.9494 2.98243 16.8022 4.2255 18.3245L5.64902 16.8923C4.76684 15.7606 4.19543 14.4086 4.01498 12.9865H2.01002ZM7.06252 18.3645L5.62897 19.7366C7.14271 20.9985 9.00732 21.7897 10.9722 22V19.997C9.54866 19.8167 8.19532 19.2459 7.06252 18.3645Z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});