define("ember-svg-jar/inlined/external-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M5.91665 4.58325V6.24992H13.075L5.08331 14.2416L6.25831 15.4166L14.25 7.42492V14.5833H15.9166V4.58325H5.91665Z\" fill=\"#1570EF\"/>\n",
    "attrs": {
      "viewBox": "0 0 21 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});