define("ember-svg-jar/inlined/icon-bars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 1.66665V0.333313H12V1.66665H0ZM0 4.33331H12V2.99998H0V4.33331ZM12 6.99998H0V5.66665H12V6.99998ZM12 9.66665H0V8.33331H12V9.66665Z\"/>",
    "attrs": {
      "viewBox": "0 0 12 10",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});