define("ember-svg-jar/inlined/file-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.76 3L19 8.409v10.817c0 .49-.175.909-.524 1.255A1.7 1.7 0 0 1 17.24 21H6.76c-.475 0-.887-.173-1.236-.52A1.702 1.702 0 0 1 5 19.227l.042-14.452c0-.49.168-.909.503-1.255A1.63 1.63 0 0 1 6.76 3h7zm-1.348 5.478h4.94l-4.94-4.695v4.695zM15.5 13.8V12h-7v1.8h7zm0 3.6v-1.8h-7v1.8h7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});