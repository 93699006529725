define("ember-svg-jar/inlined/notepad-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3 13.667l-1 1V1.333l1 1 1-1 1 1 1-1 1 1 1-1 1 1 1-1 1 1 1-1 1 1 1-1v13.334l-1-1-1 1-1-1-1 1-1-1-1 1-1-1-1 1-1-1-1 1-1-1zm9-9V6H4V4.667h8zM4 7.334v1.333h8V7.334H4zM4 10v1.334h8V10H4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});