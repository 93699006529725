define("ember-svg-jar/inlined/audio-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17.87 8.084V3.615L17.873 2l-1.388.726c-.023.012-2.356 1.198-5.744 1.198h-.956V14.49a3.227 3.227 0 0 0-1.436-.336c-1.847 0-3.349 1.56-3.349 3.48 0 1.918 1.502 3.479 3.35 3.479 1.846 0 3.348-1.561 3.348-3.48V9.754c3.412-.188 5.562-1.338 5.66-1.39l.512-.28zm-9.52 11.04c-.792 0-1.436-.668-1.436-1.49 0-.823.644-1.492 1.435-1.492.792 0 1.436.67 1.436 1.491 0 .823-.644 1.492-1.436 1.492zm7.607-12.289c-.736.302-2.229.803-4.259.927V5.884a16.323 16.323 0 0 0 4.259-.816v1.767z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});