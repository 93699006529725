define("ember-svg-jar/inlined/eye-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.99967 4.16675C5.83301 4.16675 2.27467 6.75841 0.833008 10.4167C2.27467 14.0751 5.83301 16.6667 9.99967 16.6667C14.1663 16.6667 17.7247 14.0751 19.1663 10.4167C17.7247 6.75841 14.1663 4.16675 9.99967 4.16675ZM9.99967 14.5834C7.69967 14.5834 5.83301 12.7167 5.83301 10.4167C5.83301 8.11675 7.69967 6.25008 9.99967 6.25008C12.2997 6.25008 14.1663 8.11675 14.1663 10.4167C14.1663 12.7167 12.2997 14.5834 9.99967 14.5834ZM7.49967 10.4167C7.49967 9.03341 8.61634 7.91675 9.99967 7.91675C11.383 7.91675 12.4997 9.03341 12.4997 10.4167C12.4997 11.8001 11.383 12.9167 9.99967 12.9167C8.61634 12.9167 7.49967 11.8001 7.49967 10.4167Z\"/>\n",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});