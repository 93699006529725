define("ember-svg-jar/inlined/dropdown-up-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M12.3335 8L6.3335 14L7.7435 15.41L12.3335 10.83L16.9235 15.41L18.3335 14L12.3335 8Z\" fill=\"#7C849B\"/>\n",
    "attrs": {
      "width": "25",
      "height": "24",
      "viewBox": "0 0 25 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});