define("ember-svg-jar/inlined/dropdown-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8.3 7.4L9.7 6l6 6-6 6-1.4-1.4 4.6-4.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "fill": "#bebebe"
    }
  };
  _exports.default = _default;
});