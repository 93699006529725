define("ember-svg-jar/inlined/play-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.00004 1.33337C4.32004 1.33337 1.33337 4.32004 1.33337 8.00004C1.33337 11.68 4.32004 14.6667 8.00004 14.6667C11.68 14.6667 14.6667 11.68 14.6667 8.00004C14.6667 4.32004 11.68 1.33337 8.00004 1.33337ZM6.66679 11.0001L10.6668 8.00006L6.66679 5.00006V11.0001ZM2.66679 8.00006C2.66679 10.9401 5.06012 13.3334 8.00012 13.3334C10.9401 13.3334 13.3335 10.9401 13.3335 8.00006C13.3335 5.06006 10.9401 2.66673 8.00012 2.66673C5.06012 2.66673 2.66679 5.06006 2.66679 8.00006Z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});