define("ember-svg-jar/inlined/upload-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10 6.66667V10.6667H6.00004V6.66667H3.33337L8.00004 2L12.6667 6.66667H10ZM12.6667 13.3333V12H3.33337V13.3333H12.6667Z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});