define("ember-svg-jar/inlined/trash-o-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16.875 8.25v10.5a.75.75 0 0 1-.75.75h-8.25a.75.75 0 0 1-.75-.75V8.25h-.75a.75.75 0 0 1 0-1.5h11.25a.75.75 0 1 1 0 1.5zm-1.5 0h-6.75V18h6.75zm-6-3.75h5.25a.75.75 0 0 1 .75.75V6h-6.75v-.75a.75.75 0 0 1 .75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});