define("ember-svg-jar/inlined/merge-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11 8H7.49997L12 3.5L16.5 8H13V14.41L6.99997 20.41L5.58997 19L11 13.59V8ZM18.41 19L17 20.41L13.59 17L15 15.59L18.41 19Z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});